/**
  Переменные для цветов
 */

$menuHeight: 80px;
$transition: cubic-bezier(0.51, 0.26, 0.21, 1.36);
:root {
  --cOne: 255, 88, 35;
  --cTwo: 245, 245, 245;
  --cThree: 249, 134, 0;
  --cFour: 0, 133, 255;
  --cFive: 33, 33, 33;
  --cBlack: 255, 255, 255;
  --a291258: 41, 18, 88;
  --ad1b17e: 209, 177, 126;
  --aefe6e6: 122, 113, 113;
  --ad5cccc: 88, 81, 81;
  --adcdcdc: 220, 220, 220;
  --a6c757d: 108, 117, 125;
  --acdcdcd: 205, 205, 205;
  --a011627: 1, 22, 39;
  --a6d7279: 109, 114, 121;
  --ad7deee: 215, 222, 238;
  --a979797: 151, 151, 151;
  --a507689: 80, 118, 137;
}

$cOne: var(--cOne);
$cTwo: var(--cTwo);
$cThree: var(--cThree);
$cFour: var(--cFour);
$cFive: var(--cFive);
$cBlack: var(--cBlack);
$a291258: var(--a291258);
$ad1b17e: var(--ad1b17e);
$aefe6e6: var(--aefe6e6);
$ad5cccc: var(--ad5cccc);
$adcdcdc: var(--adcdcdc);
$a6c757d: var(--a6c757d);
$acdcdcd: var(--acdcdcd);
$a011627: var(--a011627);
$a6d7279: var(--a6d7279);
$ad7deee: var(--ad7deee);
$a979797: var(--a979797);
$a507689: var(--a507689);
/**
  Переменные для времени
 */

// [
//   "#000000",
//   "#291258",
//   "#111111",
//   "#d1b17e",
//   "#a3a3a3",
//   "#efe6e6",
//   "#d5cccc",
//   "#cccccc",
//   "#dcdcdc",
//   "#6c757d",
//   "#cdcdcd",
//   "#171717",
//   "#ececec",
// ]

// [
//   "#0116276b",
//   "#000000e0",
//   "#0000009d",
//   "#6d727986",
//   "#011627a2",
//   "#d7deee2e",
//   "#979797d3",
//   "#50768970",
//   "#00000040",
//   "#00000069",
//   "#00000020",
// ]
