@import "../../styles/variables";
@import "../../styles/mixins";

.header {
  background-color: #022f2e80;
  backdrop-filter: blur(4px);
  position: fixed;
  transition: top 0.2s;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: $menuHeight;
  z-index: 9999;
}

.menu {
  text-transform: capitalize;
  font-size: 16px;
  user-select: none;
  display: flex;
  align-items: center;

  li {
    list-style-type: none;
  }

  &__brand,
  &__brand1 {
    margin-left: 10px;
    height: 100px;
    object-fit: contain;
  }

  &__list {
    display: flex;
    padding: 0;
    margin: auto;
    text-transform: initial;
  }

  &__item {
    font-weight: 300;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    & > a {
      font-weight: 500;
      color: #fff;
      text-decoration: none;
      padding: 0.5rem 1rem;
      position: relative;

      &:hover {
        color: #bbb;
      }

      &:hover::after {
        width: 70%;
        color: #bbb;
      }

      &:after {
        position: absolute;
        content: "";
        height: 2px;
        bottom: -1px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 0%;
        background: currentColor;
        -o-transition: 0.5s;
        -ms-transition: 0.5s;
        -moz-transition: 0.5s;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }

      &.active:after {
        position: absolute;
        content: "";
        height: 2px;
        bottom: -1px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 70%;
      }
    }
  }

  // .nav-link {
  //     color: rgba($cFive);
  //     padding: 0;

  //     &:hover {
  //         color: rgb($cBlack);
  //     }

  //     &:focus {
  //         color: rgb($cBlack);
  //     }
  // }

  // .dropdown.item {
  //     .nav-link {
  //         padding: 8px 12px;
  //     }
  // }

  .dropdown-menu {
    background: rgb($cTwo);
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    display: block !important;
    top: 100% !important;
    transform: translate(30px, 10px) scale(1) !important;
    inset: unset !important;
    min-width: auto;
    padding: 12px 40px 12px 12px;
    border-radius: 15px;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16),
      0px 4px 18px rgba(0, 0, 0, 0.06);
    transition: all 0.2s $transition;

    .dropdown-item {
      color: rgba($cFive);
      border-radius: 20px;
      padding: 8px 15px;
      background: transparent;

      &:hover {
        color: rgb($ad5cccc);
      }
    }

    &.show {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 10px) scale(1) !important;
      inset: unset !important;
    }
  }

  &__lang {
    color: #fff;
    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }

    .nav-item {
      padding: 8px 12px;
      border-radius: 20px;
      transition: all 0.2s ease;

      &:hover {
        color: rgb($cBlack);
      }

      &.active {
        color: #fff;
      }

      .nav-link {
        color: #fff;
        position: relative;
        display: block;

        &::after {
          margin-left: 13px;
        }
      }
    }
  }

  &__hamburger {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 30px;
    z-index: 1;
    padding-left: 10px;
    margin-right: auto;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: rgb($cBlack);
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 15px;
    }

    span:nth-child(4) {
      top: 29px;
    }

    &.active span:nth-child(1) {
      top: 15px;
      width: 0%;
      left: 50%;
    }

    &.active span:nth-child(2) {
      transform: rotate(45deg);
    }

    &.active span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &.active span:nth-child(4) {
      top: 15px;
      width: 0%;
      left: 50%;
    }
  }

  @include mq("tablet-mid") {
    .brand-name {
      display: none;
    }

    &__brand {
      display: none;
    }

    &__list {
      display: none;
    }

    &__lang {
      margin-left: auto;
    }
  }

  @include mq("tablet-mid", min) {
    &__hamburger {
      display: none;
    }
  }
}

.mob-menu {
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  transition: all 0.3s ease;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }

  &__content {
    background: rgb($cBlack);
    box-shadow: 0 0 40px rgb($cFive, calc(64 / 2.55 / 100));
    position: relative;
    width: 300px;
    max-width: calc(100% - 65px);
    height: 100%;
    z-index: 1;
    transform: translateX(100%);
    transition: all 0.3s ease;
  }

  &__header {
    background-color: rgb($cOne);
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 10px 0 30px rgb($cFive, calc(105 / 2.55 / 100));
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: $menuHeight;
  }

  &__body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - $menuHeight);
    margin-top: 10px;
    padding-bottom: 30px;
  }

  &__list {
    color: rgb($cBlack);
    padding: 0;
    margin: 0;
  }

  &__item {
    & > a {
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 1px;
      display: block;
      width: 100%;
      padding: 10px 15px;
      transition: all 0.2s ease;

      &::before {
        content: "[";
        right: 2px;
      }

      &::after {
        content: "]";
        top: 1px;
        left: 6px;
      }

      &::before,
      &::after {
        opacity: 0;
        position: relative;
        font-size: 18px;
        font-weight: 500;
        transition: all 0.2s ease;
      }

      &.active {
        box-shadow: inset 10px 10px 20px rgb($cFive, calc(32 / 2.55 / 100)),
          inset -10px -10px 20px rgb($cBlack),
          0px 5px 15px rgb($cFive, calc(32 / 2.55 / 100));

        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__social {
    color: rgb($cBlack);
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 15px;

    a {
      line-height: 0;
    }

    & svg {
      margin: 0 0.5rem;
    }
  }

  &__lang {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      font-weight: 500;
      padding: 0.5rem 1rem;
      margin-top: 10px;
      border-radius: 30px;
      transition: all 0.3s ease;

      &.active {
        color: rgb($cTwo);
        box-shadow: inset 10px 10px 20px rgb($cFive, calc(32 / 2.55 / 100)),
          inset -10px -10px 20px rgb($cBlack),
          0px 5px 15px rgb($cFive, calc(32 / 2.55 / 100));
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;

    .mob-menu__content {
      transform: translateX(0);
      transition-delay: 0.2s;
    }
  }
}

@include mq("phablet") {
  .menu__lang .dropdown-menu {
    inset: unset !important;
    padding: 10px;

    &.show {
      transform: translate(-15%, 10px) scale(1) !important;
    }
  }
}
