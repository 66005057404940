@import "variables";

.app__form {
  display: flex;
  flex-direction: column;

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInput-root {
    color: rgb($cFive);
    padding-bottom: 5px;

    &::before {
      border-bottom: 1px solid rgb($cFive) !important;
    }

    &::after {
      border-bottom: 2px solid rgb($cFive);
    }
  }

  .MuiInputLabel-root {
    color: rgb($cFive) !important;
  }

  &.light {
    .MuiInputLabel-root {
      color: rgb($cOne) !important;
    }

    .MuiInput-root {
      color: rgb($cOne);

      &::before {
        border-bottom: 1px solid rgb($cOne) !important;
      }

      &::after {
        border-bottom: 2px solid rgb($cOne);
      }
    }
  }
}
