// =============================================================================
// Media Queries
// =============================================================================

$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 576px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-mid": 992px,
  "tablet-wide": 1024px,
  "desktop-small": 1150px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// =============================================================================
// Text clamp
// =============================================================================

@mixin textClamp($clamp, $height: auto) {
  -webkit-line-clamp: $clamp;
  height: $height;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// =============================================================================
// Reponsive imgage
// =============================================================================

@mixin resImg($fit: cover, $height: auto) {
  object-fit: $fit;
  width: 100%;
  max-width: 100%;
  height: $height;
}

// =============================================================================
// Centring XY
// =============================================================================

@mixin сentreXY() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// =============================================================================
// Centring X
// =============================================================================

@mixin сentreX() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// =============================================================================
// Centring Y
// =============================================================================

@mixin сentreY() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// =============================================================================
// Clearfix
// =============================================================================

@mixin clearfix {
  &:before,
  &:after {
    display: table;
    content: " ";
  }

  &:after {
    clear: both;
  }
}

// =============================================================================
// Placeholder
// =============================================================================

@mixin placeholder($color, $size, $weight) {
  &::-webkit-input-placeholder {
    color: $color;
    font-size: $size;
    font-weight: $weight;
  }
  &:-moz-placeholder {
    color: $color;
    font-size: $size;
    font-weight: $weight;
  }
  &::-moz-placeholder {
    color: $color;
    font-size: $size;
    font-weight: $weight;
  }
  &:-ms-input-placeholder {
    color: $color;
    font-size: $size;
    font-weight: $weight;
  }
}

@mixin hero($count) {
  z-index: 1;
  position: relative;
  overflow: hidden;

  $stayTime: 2s;
  $staggerTime: 0.8s;
  $totalTime: $stayTime + $staggerTime * 2;
  $duration: $totalTime * $count;

  @keyframes anim1 {
    0% {
      opacity: 1;
      z-index: -4;
    }
    #{$staggerTime / $duration * 100}% {
      opacity: 1;
      z-index: -5;
    }
    #{($totalTime - $stayTime) / $duration * 100}% {
      opacity: 1;
      z-index: -6;
    }
    #{$totalTime / $duration * 100}% {
      opacity: 1;
      z-index: -7;
    }
    100% {
      opacity: 0;
      z-index: -8;
    }
  }

  @keyframes animrest {
    0% {
      opacity: 0;
      z-index: -4;
    }
    #{$staggerTime / $duration * 100}% {
      opacity: 1;
      z-index: -5;
    }
    #{($totalTime - $stayTime) / $duration * 100}% {
      opacity: 1;
      z-index: -6;
    }
    #{$totalTime / $duration * 100}% {
      opacity: 1;
      z-index: -7;
    }
    100% {
      opacity: 0;
      z-index: -8;
    }
  }

  & > img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -1;
    top: 0;
    opacity: 0;
    left: 0;

    @for $i from 2 through $count {
      &:nth-child(#{($i)}) {
        animation: animrest $duration ease-in infinite;
        animation-delay: ($i - 1) * $totalTime;
      }
    }

    &:nth-child(1) {
      animation: animrest $duration ease-in infinite, anim1 $duration ease-in;
      animation-delay: $duration, 0s;
    }

    // @for $i from 1 through $count {
    //   @include createCircleAnimation($i);
    //   &:nth-child(#{($i)}) {
    //     animation: circle#{($i)} $duration ease-in infinite;
    //     animation-delay: $duration - $i * $totalTime;
    //   }
    // }

    // &:nth-child(1) {
    //   opacity: 1;
    // }
  }
}
