@import "../../styles/variables";
@import "../../styles/mixins";

.svgmap {
  padding-bottom: 80px;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    text-align: center;
    color: rgb($cFive);
    font-size: 30px;
    cursor: pointer;
    display: table;
    margin: 0 auto 15px;
  }

  h5 {
    color: rgb($cFive);
    cursor: pointer;
  }
  #myWindow {
    margin-top: 20px;
    overflow: hidden;
    background: transparent;

    .Russian,
    .kz {
      transition: 0.3s;
      fill: rgb($acdcdcd);
      &:hover {
        fill: rgb($cOne);
      }
    }
  }

  .russian__hover,
  .kz__hover {
    transition: 0.3s;
    fill: rgb($cOne);
  }

  @include mq("desktop-small") {
    #myWindow {
      margin-left: -880px;
    }
  }

  @include mq("tablet-mid") {
    #myWindow {
      margin-left: -1080px;
    }
  }

  @include mq("tablet") {
    #myWindow {
      margin-left: -1000px;
      overflow: scroll;
    }
  }

  @include mq("phone-wide") {
    h2 {
      text-align: center;
      font-size: 22px !important;
    }
  }
}
