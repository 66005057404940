.pr {
  &-page {
    max-width: 1024px;
    margin: 0 auto;
    margin-top: 80px;

    display: grid;
    gap: 40px;
    padding: 40px;
  }

  &-top {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 50px;
  }

  &-top__left {
    cursor: zoom-in;
    position: relative;

    & img {
      aspect-ratio: 3/4;
      object-fit: cover;
      width: 100%;

      &:nth-child(2) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 0;
        transition: 0.3s;
      }
    }

    &:hover img:nth-child(2) {
      opacity: 1;
    }
  }

  &-top__right {
    display: grid;
    gap: 20px;
    align-content: start;
  }

  &-top__title {
  }

  &-top__list {
    display: grid;
  }

  &-top__item {
    padding: 10px 0;
    border-bottom: 1px solid #aaa;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    &-title {
      font-weight: 600;
      margin: 0;
    }

    &-text {
      margin: 0;
    }
  }

  &-text {
    font-size: 18px;
    color: #777;
    white-space: pre-wrap;
  }

  &-carousel {
    overflow: hidden;

    &-content {
      --cols: 3;
      --gap: 20px;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: calc(
        (100% - var(--gap) * (var(--cols) - 2)) / var(--cols)
      );
      gap: var(--gap);
      grid-auto-rows: minmax(0, 1fr);
      padding-left: var(--gap);
    }

    &__item {
      display: grid;
      justify-items: center;
      gap: 20px;
      text-align: center;
    }

    &__img {
      width: 100%;
      aspect-ratio: 3/4;
      object-fit: cover;
      object-position: top;
    }

    &__title {
    }
  }
}
