@import "../../styles/variables";
@import "../../styles/mixins";

.contacts {
  padding-top: 0;
  overflow: hidden;

  &__header {
    background-image: url("../../resources/img/Contacts/headphone.jpg");
    margin-top: -80px;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    aspect-ratio: 16/8;

    .contacts__overlay {
      margin-top: 80px;
      background: rgb($a011627, calc(107 / 2.55 / 100));
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 80px;
        font-weight: 700;
        color: rgb($cBlack);
      }

      span {
        color: rgb($cBlack);
      }
    }
  }

  &__contact {
    background-attachment: fixed;
    background-image: url("../../resources/img/Contacts/contact-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &-overlay {
      padding: 120px 0;
      background-color: #0009;
    }
  }

  .contacts-form-info {
    padding: 30px 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba($cBlack, 0.694);

    .form-info-inner {
      margin-bottom: 40px;
      display: flex;
      color: rgb($cFive);

      svg {
        color: rgb($cOne);
        font-size: 40px;
      }

      h5 {
        font-size: 25px;
      }

      span {
        font-size: 15px;
      }
    }
  }

  @include mq("phone-wide") {
    &__header {
      .contacts__overlay {
        h1 {
          font-size: 50px;
        }
      }
    }
  }
}
