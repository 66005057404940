@import "../../styles/variables";
@import "../../styles/mixins";

.servicesection {
  background-color: rgb($cBlack);

  &__sup {
    text-align: center;
    color: #000;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 1.3em;
    letter-spacing: 1px;
  }

  &__sub {
    margin: 10px auto 30px;
    width: 80%;
    text-align: center;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }

  .service-img1 {
    background-image: url("../../resources/img/Main/models.jpg");
  }
  .service-img2 {
    background-image: url("../../resources/img/Main/print.jpg");
  }
  .service-img3 {
    background-image: url("../../resources/img/Main/цех.jpg");
  }

  &__box {
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;

    padding: 30px;

    &-text {
      opacity: 0.9;
      border-radius: 5px;
      padding: 30px;
      background-color: #0009;

      img {
        margin-bottom: 10px;
        width: 75px;
        margin-left: auto;
        margin-right: auto;
      }

      h3 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        color: rgb($cBlack);
      }

      p {
        min-height: 120px;
        margin-top: 10px;
        word-spacing: 1px;
        color: rgb($cBlack);
      }

      .button {
        background-color: rgb($cOne);
        padding: 10px;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  @include mq("tablet-small") {
    &__box {
      margin-bottom: 20px;
    }
  }

  &-tab {
    background-size: contain;
    background-image: url("../../resources/img/Main/bg-tab.jpg");
    &-layout {
      background-color: rgba(0, 0, 0, 0.765);
    }
  }

  &-counter {
    background-size: cover;
    background-image: url("../../resources/img/Main/counter-bg.png");

    &-layout {
      padding: 100px 0;
      background-color: #022f2e;
      .col-md-3 {
        display: flex;
        text-align: center;
        flex-direction: column;

        img {
          filter: brightness(0) invert(1);
          margin: 0 auto 10px;
          width: 70px;
          height: 70px;
          object-fit: contain;
        }

        span {
          color: #fff;
          font-size: 50px;
          font-weight: 200;
          line-height: 60px;
        }

        h6 {
          font-size: 17px;
          color: #fff;
          font-family: "Poppins", Sans-serif;
          font-weight: 600;
        }
      }
    }
  }

  @include mq("tablet") {
    &-counter {
      &-layout {
        padding: 70px 0;
        .col-md-3 {
          margin-bottom: 20px;
          img {
            margin: 0 auto 0;
          }

          span {
            font-size: 40px;
            line-height: 45px;
          }
        }
      }
    }
  }
}
