@import "../../styles/variables";
@import "../../styles/mixins";

.my-form {
  margin-top: 30px;

  h6 {
    font-size: 18px;
    margin-top: 15px;
    font-weight: 600;
  }

  &-info {
    margin-bottom: 40px;
    display: flex;
    color: rgb($cFive);

    svg {
      color: rgb($cOne);
      font-size: 40px;
    }

    h5 {
      font-size: 25px;
    }

    span {
      font-size: 15px;
    }
  }

  &-right {
    padding: 30px 30px 0 30px;
    background-color: rgb($cBlack);
  }

  .MuiInputLabel-root {
    color: rgb($cFive) !important;
  }

  .Mui-focused,
  .MuiOutlinedInput-input,
  .MuiInput-input {
    color: rgb($cFive) !important;
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border-color: rgb($cFive) !important;
    }
  }
}
