@import "../../styles/variables";
@import "../../styles/mixins";

.products {
  padding-top: 0;
  overflow: hidden;

  &__wrapper {
  }

  &__info {
    display: grid;
    gap: 2px;
    align-content: start;
    margin-top: 140px;
    position: sticky;
    top: 0;

    .products & {
      h4 {
        border-top: 1px solid #ccc;
        padding-top: 1rem;
        font-size: 20px;
      }

      p {
        font-size: 16px;
        color: #777;
      }
    }
  }

  &__header {
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    aspect-ratio: 16/8;
    @include hero(4);

    & img {
      object-position: top;
    }

    .products__overlay {
      background: rgb($a011627, calc(107 / 2.55 / 100));
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 80px;
        font-weight: 700;
        color: rgb($cBlack);
      }

      p {
        margin-bottom: 0;
        color: rgb($cBlack);
      }
    }
  }

  h3 {
    margin-top: 50px;
    font-weight: 600;
    text-align: center;
  }

  .MuiButtonBase-root {
    color: rgb($cFive);
    font-family: "Mons";
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
  .css-1q2h7u5.Mui-selected,
  .MuiTab-root .MuiTab-textColorPrimary {
    color: rgb($cFive);
    font-family: "Mons";
  }

  .MuiTabs-indicator {
    background-color: rgb($cOne);
  }

  img {
    margin-bottom: 20px;
    width: 100%;
    object-fit: cover;
    height: auto;
    cursor: pointer;
  }
}
