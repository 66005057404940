@import "../../styles/variables";
@import "../../styles/mixins";

.intro {
  padding-top: 0;
  overflow: hidden;

  &__header {
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;

    @include hero(4);
  }

  .videoTag {
    z-index: -1;
    width: 100%;
    position: absolute;
  }

  .intro__overlay {
    aspect-ratio: 16 / 8;
    background: rgb($a011627, calc(107 / 2.55 / 100));
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      max-width: 700px;
      font-weight: 600;
      color: rgb($cBlack);
      font-size: 58px;
      transform: scale(0.94);
      animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
      span {
        margin-right: 12px;
      }
    }

    @keyframes scale {
      100% {
        transform: scale(1);
      }
    }

    span {
      display: inline-block;
      opacity: 0;
      filter: blur(4px);
    }

    span:nth-child(1) {
      animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(2) {
      animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(3) {
      animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(4) {
      animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(5) {
      animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(6) {
      animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(7) {
      animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(8) {
      animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(9) {
      animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(10) {
      animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(11) {
      animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(12) {
      animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(13) {
      animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(14) {
      animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(15) {
      animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(16) {
      animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(17) {
      animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(18) {
      animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(19) {
      animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    span:nth-child(20) {
      animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    }

    @keyframes fade-in {
      100% {
        opacity: 1;
        filter: blur(0);
      }
    }

    p {
      color: rgb($cBlack);
      width: 80%;
      margin-bottom: 40px;
      span {
        margin-right: 6px;
      }
    }
  }

  @include mq("desktop-small") {
    .videoTag {
      display: none;
    }
  }

  @include mq("tablet-small") {
    .intro__overlay {
      h1 {
        font-size: 33px;
        span {
          margin-right: 0;
        }
      }

      p {
        width: 100%;
        font-size: 15px;
      }
    }
  }
}
