@import "../../styles/variables";
@import "../../styles/mixins";

.about {
  padding-top: 0;
  overflow: hidden;
  background-color: rgb($cBlack);

  &__header {
    margin-top: -80px;
    height: auto;
    z-index: 1;
    position: relative;

    @include hero(4);

    .about__overlay {
      aspect-ratio: 16 / 8;
      margin-top: 80px;
      background: rgb($a011627, calc(107 / 2.55 / 100));
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        line-height: 1.3;
        max-width: 700px;
        font-size: 55px;
        font-weight: 700;
        color: rgb($cBlack);
        animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);

        span {
          margin-right: 12px;
        }
      }

      span {
        display: inline-block;
        opacity: 0;
        filter: blur(4px);
      }

      span:nth-child(1) {
        animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(2) {
        animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(3) {
        animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(4) {
        animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(5) {
        animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(6) {
        animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(7) {
        animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(8) {
        animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(9) {
        animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(10) {
        animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(11) {
        animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(12) {
        animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(13) {
        animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(14) {
        animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(15) {
        animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(16) {
        animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(17) {
        animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(18) {
        animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(19) {
        animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      span:nth-child(20) {
        animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
      }

      p {
        margin-bottom: 40px;
        max-width: 700px;
        color: rgb($cBlack);
        span {
          margin-right: 6px;
        }
      }
    }
  }

  &__story {
    &-left {
      background-image: url("../../resources/img/About/3F4A8364.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-right {
      background-color: rgb($cTwo);

      &-box {
        padding: 50px 10px 50px 30px;

        .about__story-sup {
          color: rgb($cOne);
          font-size: 20px;
        }

        .about__story-sub {
          margin-bottom: 15px;
          color: rgb($cFive);
          font-size: 30px;
          line-height: 1.2em;
        }

        p {
          color: rgb($cFive);

          &.parag3 {
            margin-bottom: 5px;
          }
        }

        ul {
          list-style-type: none;

          li {
            color: rgb($cFive);

            svg {
              color: rgb($cOne);
            }
          }
        }
      }
    }
  }

  .about__facilities {
    background-color: rgb($cBlack);

    &-img {
      display: grid;
      margin-bottom: 80px;

      & > div {
        padding: 10vh max(20px, calc(50% - 660px));
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 48px;
        align-items: center;

        &:nth-child(2n) {
          background-color: #022f2e40;

          & > div {
            order: -1;
          }
        }
      }

      & img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 16 / 11;
      }

      & .h-0 {
        height: 0;
      }

      & button {
        color: #000;
      }
    }

    &-text {
      h5 {
        color: rgb($cOne);
        font-size: 18px;
      }

      h4 {
        margin-bottom: 15px;
        color: rgb($cFive);
        font-size: 25px;
        line-height: 1.2em;
      }

      p {
        color: rgb($cFive);
        white-space: pre-wrap;
      }
    }
  }

  &__contact {
    background-attachment: fixed;
    background-image: url("../../resources/img/Contacts/contact-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &-overlay {
      padding: 120px 0;
      background-color: rgb($cFive, calc(157 / 2.55 / 100));
    }
  }

  .my-form-info {
    margin-bottom: 40px;
    display: flex;
    color: rgb($cBlack);

    svg {
      color: rgb($cOne);
      font-size: 40px;
    }

    h5 {
      font-size: 25px;
    }

    span {
      font-size: 15px;
    }
  }

  @include mq("tablet-mid") {
    @keyframes animateabout {
      100%,
      0%,
      18% {
        background-size: cover;
      }
      33%,
      48% {
        background-size: cover;
      }
      63%,
      78% {
        background-size: cover;
      }
    }
  }
}

.advantage {
  &__mission {
    justify-content: center;
    padding: 70px 0 0;

    h2 {
      font-weight: 700;
      font-size: 45px;
      text-align: center;
      margin-bottom: 30px;
    }

    .col-md-5 {
      margin-bottom: 30px;
    }

    &-box {
      border-radius: 10px;
      padding: 35px 35px 0 35px;
      height: 100%;
      box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 22%);
      background-color: #022f2e;
      color: #fff;
      transition: 0.3s;

      &:hover {
        background-color: #fff;
        color: #000;

        p {
          color: #333;
        }
      }

      svg {
        margin-bottom: 15px;
        font-size: 55px;
      }

      h3 {
        font-size: 25px;
        margin-bottom: 20px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 1.8;
        color: #022f2e;
        transition: 0.3s;
      }
    }
  }
}
