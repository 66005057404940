@import "../../styles/variables";
@import "../../styles/mixins";

.advantage {
  background-color: rgb($cBlack);
  text-align: center;

  p {
    font-size: 20px;
    margin: 15px auto;
    max-width: 700px;
    color: rgb($cFive);
  }

  h2 {
    color: rgb($cFive);
    font-weight: 700;
    font-size: 45px;

    span {
      color: rgb($cOne);
    }
  }

  &__sub {
    font-size: 1.15rem !important;
    max-width: 75% !important;
  }

  &__box {
    position: relative;

    &-img {
      display: inline-flex;
      width: 200px;
      height: 200px;
      line-height: 200px;
      margin: 0 auto;
      border-radius: 50%;
      justify-content: center;
      z-index: 2;
      text-align: center;
      border-style: solid;
      border-width: 0px 0px 0px 0px;
      box-shadow: 0px 0px 0px 2px rgb($cOne);
      z-index: 111;

      img {
        object-position: left;
        z-index: -11;
        object-fit: cover;
        width: 100%;
        // opacity: 0.8;
        border-radius: 50%;
        height: auto;
      }
    }

    span {
      display: none;
      position: absolute;
      top: 50%;
      left: 15px;
      right: 15px;
      z-index: 2;
      font-size: 30px;
      color: transparent;
      font-weight: 600;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-family: Sans-serif;
      font-size: 70px;
      letter-spacing: 4px;
      -webkit-text-stroke-color: rgb($cFive);
      -webkit-text-stroke-width: 3px;
    }
  }

  @include mq("tablet") {
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 17px;
      margin: 15px auto;
      max-width: 700px;
      color: rgb($cFive);
    }

    &__sub {
      font-size: 1.2rem !important;
      max-width: 75% !important;
    }
  }
}
