@import "../../styles/variables";
@import "../../styles/mixins";

.aboutsection {
  padding-top: 0;
  background-image: url("../../resources/img/Main/bg-man.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  height: 450px;
  max-height: 100%;

  &-overlay {
    backdrop-filter: blur(1px);
    background: rgb($a011627, calc(162 / 2.55 / 100));
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    p {
      word-spacing: 1px;
      color: rgb($cBlack);
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  @include mq("tablet-small") {
    &-overlay {
      p {
        word-spacing: 0;
        font-size: 25px;
      }
    }
  }
}
