@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
  background-color: rgb($cTwo);
  color: rgb($cFive);
  margin-top: auto;
  padding: 80px 0 10px;

  h3 {
    font-size: 23px;
    color: rgb($cFive);
  }

  a:has(img) {
    display: inline-block;
  }

  img {
    width: 170px;
    aspect-ratio: auto;
    display: block;
  }

  &__link {
    display: flex;
    flex-direction: column;

    a {
      margin-top: 9px;

      &:hover {
        color: rgb($cOne);
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px !important;
  }

  @include mq("tablet") {
    .col-md-4 {
      text-align: center;
      margin-bottom: 30px;

      &::after {
        content: "";
        display: block;
        height: 2px;
        background-color: rgb($adcdcdc);
        width: 60px;
        margin: 25px auto 0;
      }
    }
  }

  @include mq("phone-wide") {
    &__bottom {
      margin-top: 40px !important;
      flex-direction: column;
      align-items: center;
      line-height: 2;
    }
  }
}

.socials {
  display: flex;
  gap: 20px;
  margin-top: 20px;

  & a {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    padding: 8px;
    border-radius: 999px;
    border: 1px solid #000;
    aspect-ratio: 1;
    transition: 0.3s;

    &:hover {
      color: rgb($cOne);
      border-color: rgb($cOne);
    }
  }
}
