@import "./variables";

.button {
  color: rgb($cBlack);
  background-color: transparent;
  border: 2px solid rgb($cOne);
  margin-top: 25px;
  padding: 1em 2.5em;

  text-decoration: none;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    color: rgb($cBlack);
    outline: 0;
  }

  &--dark {
    color: rgb($cFive);
  }
}

.button-fourth {
  border-color: rgb($cOne);
  background: {
    image: linear-gradient(45deg, rgb($cOne) 50%, transparent 50%);
    position: 100%;
    size: 400%;
  }
  transition: background 300ms ease-in-out;

  &:hover {
    background-position: 0;
  }
}
