@import "../../styles/variables";
@import "../../styles/mixins";

.tabs__component {
  padding: 80px 0;
  text-align: center;
  a {
    font-size: 17px;
    color: rgb($cOne) !important;
  }

  h4 {
    color: rgb($cBlack);
    font-weight: 700;
    margin: 10px 0 30px;
    line-height: 1.3em;
    letter-spacing: 1px;
  }

  .MuiButtonBase-root {
    color: rgb($cBlack);
    font-family: "Mons";
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
  .css-1q2h7u5.Mui-selected,
  .MuiTab-root .MuiTab-textColorPrimary {
    color: rgb($cBlack);
    font-family: "Mons";
  }

  .MuiTabs-indicator {
    background-color: rgb($cOne);
  }

  img {
    margin-bottom: 20px;
    width: 100%;
    object-fit: cover;
    height: auto;
    cursor: pointer;
  }
}

.product {
  display: grid;
  justify-items: center;
  color: #333;

  &.dark {
    color: #eee !important;

    & .button {
      color: #eee !important;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__title {
    font-size: 18px;
    // text-align: center;
    margin-top: 5px;
    font-weight: 500;
    line-height: 1.3em;
    margin-bottom: 5px;
  }

  &__color {
    font-size: 16px;
    // text-align: center;
    // margin-top: 2px;
    font-weight: 400;
    line-height: 1.3em;
    color: #555;
    margin-bottom: 10px;

    .dark & {
      color: #bbb;
    }
  }

  & .button {
    margin: 0 !important;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    padding: 10px 30px;
  }
}

.col-md-4:has(.product-hoverable-top) {
  margin-bottom: 20px;
}

.col-md-3:has(.product-hoverable-top) {
  margin-bottom: 20px;
}

.product-hoverable-top.product-hoverable-top.product-hoverable-top {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;

  & img {
    margin: 0;
  }
}

.product-hoverable.product-hoverable.product-hoverable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 11;
  transition: 0.3s;
  // padding: 0 calc(var(--bs-gutter-x) * 0.5);

  &:hover {
    opacity: 1;
  }

  & img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
